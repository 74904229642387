var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('section',{staticClass:"form"},[_c('h3',{staticClass:"form__header"},[_vm._v(" Timeslots "),_c('div',{staticClass:"form__header_part"},[_c('BaseButton',{staticClass:"button button--xxs button--outline button--uppercase",attrs:{"disabled":_vm.currentWeekIndex === 0,"text":"Previous Week"},on:{"click":_vm.goToPreviousWeek}}),_c('BaseButton',{staticClass:"button button--xxs button--outline button--uppercase",attrs:{"disabled":_vm.currentWeekIndex >= _vm.maxWeekIndex,"text":"Next Week"},on:{"click":_vm.goToNextWeek}})],1)]),_c('div',{staticClass:"timeslots-calendar"},[_c('div',{staticClass:"timeslots-calendar__grid"},_vm._l((_vm.currentWeekData),function(day){return _c('div',{key:day.date,staticClass:"timeslots-calendar__column"},[_c('button',{class:`timeslots-calendar__column-item ${
              !day.isVisible && 'timeslots-calendar__column-item--disabled'
            } ${
              _vm.selectedSlot.date === day.date &&
              !_vm.selectedSlot.time &&
              'timeslots-calendar__column-item--selected'
            }`,attrs:{"disabled":!day.isVisible},on:{"click":function($event){return _vm.onTimeSlotClick(day.date, '')}}},[_c('h3',[_vm._v(_vm._s(day.date ? _vm.$moment(day.date).format("MMM") : ""))]),_c('p',[_vm._v(_vm._s(day.date ? _vm.$moment(day.date).format("DD") : "N/A"))])]),_vm._l((day.times),function(time){return _c('button',{key:time.value,staticClass:"timeslots-calendar__column-item",class:{
              'timeslots-calendar__column-item--selected':
                (_vm.selectedSlot.date === day.date && _vm.selectedSlot.time === time.value) ||
                (_vm.selectedSlot.date === day.date && !_vm.selectedSlot.time),
              'timeslots-calendar__column-item--disabled': !time.selected || day.disabled,
            },attrs:{"disabled":!time.selected || day.disabled},on:{"click":function($event){return _vm.onTimeSlotClick(day.date, time.value)}}},[_c('p',[_vm._v(_vm._s(time.value))])])})],2)}),0)])]),_c('section',[_c('h3',{staticClass:"form__header"},[_vm._v("RSVP List")]),_c('Table',{attrs:{"className":"simple","loading":false,"items":_vm.list,"headerItems":_vm.headerItems,"tableData":_vm.tableData,"hasMorePages":false},on:{"clickAction":_vm.clickAction,"sortTable":_vm.sortTable}})],1),(_vm.modal)?_c('EditReservation',{attrs:{"modal":_vm.modal,"entityId":_vm.id,"item":_vm.item},on:{"hideModal":_vm.hideModal,"updated":_vm.updated,"remove":_vm.deleteReservation}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }