<template>
  <section class="form">
    <h3 class="form__header">
      Add Timeslots
      <BaseButton
        class="button--admin button--green button--xxs"
        text="Save Timeslots"
        :disabled="!userInteraction || times.filter(el => el.selected).length === 0"
        @click="addTimeslots"
      >
      </BaseButton>
    </h3>
    <div class="form__row">
      <div class="form__row_col">
        <DatePicker
          placeholder="Day"
          :required="true"
          :validateError="serverError.start_date"
          :value="date"
          v-model="date"
          :startDate="start_date"
          :endDate="end_date"
          :time="false"
          format="YYYY-MM-DD"
          @input="changeDate"
        ></DatePicker>
      </div>
      <div v-if="date" class="form__row_col">
        <p>Select Timeslots</p>
        <div class="timeslots">
          <button
            v-for="time in times"
            :key="time.value"
            class="timeslots-item"
            :class="{
              'timeslots-item--selected': time.selected,
            }"
            @click="onTimeSlotClick(time.value)"
          >
            {{ time.value }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import DatePicker from "@/components/inputs/DatePicker.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  props: {
    id: { type: Number, required: true },
    start_date: { type: String, required: true },
    end_date: { type: String, required: true },
  },
  components: {
    DatePicker,
    BaseButton,
  },
  data() {
    return {
      date: null,
      times: [],
      userInteraction: false,
    };
  },
  async created() {
    this.times = this.timeslotAvailability;
  },
  computed: {
    ...mapGetters("exhibitions", {
      timeslotAvailability: "getTimeslotAvailability",
      serverError: "getServerError",
    }),
  },
  watch: {
    timeslotAvailability(newVal) {
      this.times = newVal;
    },
  },
  methods: {
    ...mapActions("exhibitions", [
      "apiGetExhibitionTimeslotAvailability",
      "apiPostExhibitionTimeslotAvailability",
      "apiGetExhibitionTimeslots",
    ]),
    async changeDate($event) {
      if ($event != null) {
        const date = $event;
        this.date = date;
        await this.apiGetExhibitionTimeslotAvailability({
          id: this.id,
          date: this.$moment(date).format("YYYY-MM-DD"),
        });
      }
    },
    async addTimeslots() {
      const success = await this.apiPostExhibitionTimeslotAvailability({
        id: this.id,
        date: this.$moment(this.date).format("YYYY-MM-DD"),
        times: this.times.filter(el => el.selected).map(el => el.value),
      });
      if (success) {
        await this.apiGetExhibitionTimeslots({ id: this.id });
        this.date = null;
      }
    },
    onTimeSlotClick(time) {
      const t = this.times.map(item => {
        if (item.value === time) {
          return { ...item, selected: !item.selected };
        } else return item;
      });
      this.times = t;
      this.userInteraction = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.timeslots {
  display: flex;
  gap: 8px;
  &-item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 18px 8px;
    border-radius: 12px;
    border: 1px solid #707070;
    background: $white;
    @include type($fs: 14px, $lh: 1.25, $fw: 600);
    color: $black;
    &--selected,
    &:hover {
      border-color: #45755a;
      background: #45755a4d;
    }
  }
}
</style>

