<template>
  <PopUp :show="modal" @hideModal="hideModal">
    <ValidationObserver
      v-slot="{ invalid }"
      tag="div"
      ref="observer"
      class="exhibition"
      id="scroll-block"
    >
      <header class="exhibition__header">
        <h3 class="exhibition__header_title">{{ exhibition.id ? "Edit" : "Add" }} Exhibition</h3>
        <div class="exhibition__header_actions">
          <BaseButton
            v-if="item"
            class="button--sm button--outline button--uppercase"
            text="Delete Exhibition"
            @click="$emit('removeExhibition', exhibition)"
          ></BaseButton>
          <BaseButton
            class="button--sm button--green button--uppercase"
            text="Save Changes"
            :loading="changesLoading"
            :disabled="
              invalid ||
              exhibition.search_image === '' ||
              (exhibition.featured && exhibition.home_page_image === '') ||
              !changed
            "
            @click="saveExhibition"
          ></BaseButton>
        </div>
      </header>
      <section class="form">
        <div v-if="exhibition.status" class="form__header">
          <SimpleToggle
            placeholder="Featured"
            :disabled="
              invalid ||
              exhibition.search_image == '' ||
              exhibition.home_page_image == '' ||
              exhibition.copyright == '' ||
              !exhibition.status
            "
            v-model="exhibition.featured"
            :selected="+exhibition.featured"
            :items="[
              { id: '1', title: 'Active' },
              { id: '0', title: 'Inactive' },
            ]"
            @input="exhibition.featured = $event"
          ></SimpleToggle>
          <p v-if="!exhibition.status" class="form__header_notification">
            Please make this exhibition Enabled before using this option
          </p>
          <p v-if="!exhibition.home_page_image" class="form__header_notification">
            Please add a homepage image before using this option
          </p>
          <p v-if="!exhibition.copyright" class="form__header_notification">
            Please fill in the copyright field before using this option
          </p>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Exhibition Information</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Title"
              :required="true"
              :validateError="errors[0] || serverError.title"
              v-model="exhibition.title"
              :element="exhibition.title"
              @input="exhibition.title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true }"
          >
            <BaseSelect
              placeholder="Exhibition Type"
              v-model="exhibition.exhibition_type_id"
              :selected="exhibition.exhibition_type_id"
              :items="exhibition_types"
              :required="true"
              @input="exhibition.exhibition_type_id = $event.id"
            ></BaseSelect>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side">
            <Toggle
              placeholder="Active Toggle"
              v-model="exhibition.status"
              :disabled="invalid || exhibition.search_image === ''"
              :selected="+exhibition.status"
              :items="[
                { id: '1', title: 'Active' },
                { id: '0', title: 'Inactive' },
              ]"
              @input="exhibition.status = $event"
            ></Toggle>
          </div>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, path: true, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Path"
              :required="true"
              :validateError="errors[0] || serverError.slug"
              v-model="exhibition.slug"
              :element="exhibition.slug"
              @input="exhibition.slug = $event"
            >
              <template #text>
                <span class="form-control__simple-text">
                  Example: {{ `${url}/exhibitions/${exhibition.slug || "path"}` }}
                </span>
              </template>
            </BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Address"
              :validateError="errors[0]"
              v-model="exhibition.address"
              :element="exhibition.address"
              @input="exhibition.address = $event"
            >
            </BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <div class="form__row_col form__row_col--side">
            <DatePicker
              placeholder="Start Date"
              :required="true"
              :validateError="serverError.start_date"
              :value="exhibition.start_date"
              v-model="exhibition.start_date"
              @input="exhibition.start_date = $event"
              format="YYYY-MM-DD hh:mm"
              :use12h="false"
              :disabled="exhibition.id && exhibition.timeslots?.length > 0"
            ></DatePicker>
          </div>
          <div class="form__row_col form__row_col--side">
            <DatePicker
              placeholder="End Date"
              :required="true"
              :validateError="serverError.end_date"
              :startDate="exhibition.start_date"
              :value="exhibition.end_date"
              v-model="exhibition.end_date"
              @input="exhibition.end_date = $event"
              format="YYYY-MM-DD hh:mm"
              :use12h="false"
              :disabled="exhibition.id && exhibition.timeslots?.length > 0"
            ></DatePicker>
          </div>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ min: 2, max: 65000 }"
            v-slot="{ errors }"
          >
            <Editor
              placeholder="Dates & Hours Description"
              :validateError="errors[0]"
              :value="exhibition.schedule"
              @input="exhibition.schedule = $event"
              v-model="exhibition.schedule"
              :toolbar="['bold', 'italic']"
            ></Editor>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true }"
            v-slot="{ errors }"
          >
            <BaseNumberInput
              placeholder="Number of guests limit"
              :required="true"
              v-model="exhibition.guests_limit"
              :element="exhibition.guests_limit"
              @input="exhibition.guests_limit = $event"
            ></BaseNumberInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider tag="div" class="form__row_col" v-slot="{ errors }">
            <MultipleAutocompleteInput
              id="autocomplete-category"
              :class="{ 'autocomplete-input--invalid': errors[0] }"
              v-model="exhibition.artists"
              :creating="false"
              :focusOpen="true"
              :required="false"
              :validateError="errors[0]"
              :loading="artistsLoading"
              :items="exhibition.artists"
              :matches="artists"
              placeholder="Artists"
              @input="exhibition.artists = $event"
              @searchMatches="getSearchedArtists"
            ></MultipleAutocompleteInput>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Exhibition Description</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 65535 }"
            v-slot="{ errors }"
          >
            <Editor
              placeholder="Description"
              :validateError="errors[0]"
              :value="exhibition.description"
              @input="exhibition.description = $event"
              v-model="exhibition.description"
              :toolbar="['bold', 'italic']"
            ></Editor>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 65535 }"
            v-slot="{ errors }"
          >
            <Editor
              placeholder="Exhibition Homepage"
              :validateError="errors[0]"
              :value="exhibition.home_description"
              @input="exhibition.home_description = $event"
              v-model="exhibition.home_description"
              :toolbar="['bold', 'italic']"
            ></Editor>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Button Text"
              :validateError="errors[0]"
              v-model="exhibition.button_text"
              :element="exhibition.button_text"
              @input="exhibition.button_text = $event"
            >
            </BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Button URL"
              :validateError="errors[0]"
              v-model="exhibition.button_url"
              :element="exhibition.button_url"
              @input="exhibition.button_url = $event"
            >
            </BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <div class="form__row_col">
            <Toggle
              placeholder="Safety Protocol"
              v-model="exhibition.safety_protocol"
              :selected="+exhibition.safety_protocol"
              :items="[
                { id: '1', title: 'Active' },
                { id: '0', title: 'Inactive' },
              ]"
              @input="exhibition.safety_protocol = $event"
            ></Toggle>
          </div>
          <!-- <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="RSVP Button URL"
              :validateError="errors[0]"
              v-model="exhibition.button_rsvp_url"
              :element="exhibition.button_rsvp_url"
              @input="exhibition.button_rsvp_url = $event"
            >
            </BaseInput>
          </ValidationProvider> -->
        </div>
        <div class="form__row" v-if="+exhibition.safety_protocol === 1">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{
              required: true,
              min: 2,
              max: 65535,
            }"
            v-slot="{ errors }"
          >
            <Editor
              placeholder="Safety Protocol Information"
              :required="true"
              :validateError="errors[0]"
              :value="exhibition.safety_protocol_info"
              @input="exhibition.safety_protocol_info = $event"
              v-model="exhibition.safety_protocol_info"
              :toolbar="[
                'heading',
                'bold',
                'italic',
                'underline',
                'link',
                'blockQuote',
                '|',
                'alignment',
                'numberedList',
                'bulletedList',
              ]"
            ></Editor>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Video</h3>
        <div
          class="form__row form__row--bordered form__row--justify-start"
          style="padding: 8px; align-items: flex-start"
        >
          <span class="form__row_label">Video </span>
          <div>
            <DragAndDropImage
              type="video"
              id="video"
              :accept="['mp4', 'mov', 'webm']"
              size="15"
              :image="exhibition.videos.url"
              @input="exhibition.videos.url = $event.image"
              @removeImage="
                exhibition.videos = {
                  url: '',
                  caption: '',
                }
              "
              style="margin: 4px"
            ></DragAndDropImage>
            <BaseInput
              v-if="exhibition.videos.url"
              class="form-control--xs"
              style="margin-top: 20px"
              placeholder="Caption"
              v-model="exhibition.videos.caption"
              :element="exhibition.videos.caption"
              @input="exhibition.videos.caption = $event"
            ></BaseInput>
          </div>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Exhibition Images</h3>
        <div class="form__row">
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label"
              >Search Image<span class="form__row_label_required">*</span></span
            >
            <DragAndDropImage
              class="uploader--full-width"
              id="search"
              size="15"
              :image="exhibition.search_image"
              :v-model="exhibition.search_image"
              @input="
                exhibition.search_image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                exhibition.search_image = '';
              "
            ></DragAndDropImage>
          </div>
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label">Header Image </span>
            <DragAndDropImage
              class="uploader--full-width"
              id="header"
              size="15"
              :image="exhibition.header_image"
              :v-model="exhibition.header_image"
              @input="
                exhibition.header_image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                exhibition.header_image = '';
              "
            ></DragAndDropImage>
          </div>
        </div>
        <div class="form__row form__row--bordered">
          <span class="form__row_label">
            Homepage Image
            <span v-if="exhibition.featured" class="form__row_label_required">*</span>
          </span>
          <DragAndDropImage
            class="uploader--full-width"
            id="home"
            size="15"
            :image="exhibition.home_page_image"
            :v-model="exhibition.home_page_image"
            @input="
              exhibition.home_page_image = $event.image;
              $event.removed ? removedImages.push($event.removed) : false;
            "
            @removeImage="
              removedImages.push($event);
              exhibition.home_page_image = '';
            "
          ></DragAndDropImage>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: exhibition.featured, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Copyright"
              :required="exhibition.featured"
              :validateError="errors[0] || serverError.copyright"
              v-model="exhibition.copyright"
              :element="exhibition.copyright"
              @input="exhibition.copyright = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">Catalog</h3>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Catalog Title"
              :validateError="errors[0]"
              v-model="exhibition.catalog_title"
              :element="exhibition.catalog_title"
              @input="exhibition.catalog_title = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ max: 65535 }"
            v-slot="{ errors }"
          >
            <Editor
              placeholder="Catalog Description"
              :validateError="errors[0]"
              :value="exhibition.catalog_description"
              @input="exhibition.catalog_description = $event"
              v-model="exhibition.catalog_description"
              :toolbar="['bold', 'italic']"
            ></Editor>
          </ValidationProvider>
        </div>
        <div class="form__row form__row--bordered">
          <span class="form__row_label">Catalog Image</span>
          <DragAndDropImage
            class="uploader--full-width"
            id="catalog"
            size="15"
            :image="exhibition.catalog_image"
            :v-model="exhibition.catalog_image"
            @input="
              exhibition.catalog_image = $event.image;
              $event.removed ? removedImages.push($event.removed) : false;
            "
            @removeImage="
              removedImages.push($event);
              exhibition.catalog_image = '';
            "
          ></DragAndDropImage>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Catalog Button Text"
              :validateError="errors[0]"
              v-model="exhibition.catalog_button_text"
              :element="exhibition.catalog_button_text"
              @input="exhibition.catalog_button_text = $event"
            >
            </BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Catalog Button URL"
              :validateError="errors[0]"
              v-model="exhibition.catalog_button_url"
              :element="exhibition.catalog_button_url"
              @input="exhibition.catalog_button_url = $event"
            >
            </BaseInput>
          </ValidationProvider>
        </div>
      </section>
      <section class="form" v-for="(sponsor, id) in exhibition.sponsors" :key="id">
        <h3 class="form__header">
          Sponsor {{ id + 1 }}
          <IconButton
            class="exhibition__sponsor-button button--admin button--xsm"
            text="Remove Sponsor"
            @click="removeSponsor(id)"
          >
            <IconClose width="9" height="9"></IconClose>
          </IconButton>
        </h3>
        <div class="form__row form__row--align-start">
          <div class="form__row_col form__row_col--side form__row_col--bordered">
            <span class="form__row_label">Sponsor Image</span>
            <DragAndDropImage
              class="uploader--full-width"
              :id="`sponsor-${id}`"
              size="15"
              :canRemove="false"
              :image="sponsor.image"
              :v-model="sponsor.image"
              @input="
                sponsor.image = $event.image;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                sponsor.image = '';
              "
            ></DragAndDropImage>
            <BaseInput
              class="form-control--xs"
              style="margin-top: 20px"
              placeholder="Caption"
              v-model="sponsor.caption"
              :element="sponsor.caption"
              @input="sponsor.caption = $event"
            ></BaseInput>
          </div>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Sponsor URL"
              :validateError="errors[0]"
              v-model="sponsor.url"
              :element="sponsor.url"
              @input="sponsor.url = $event"
            >
            </BaseInput>
          </ValidationProvider>
        </div>
      </section>
      <section class="form">
        <h3 class="form__header">
          Sponsor
          <IconButton
            class="exhibition__sponsor-button button--admin button--xsm"
            text="Add Sponsor"
            @click="addSponsor(sponsor)"
          >
            <IconPlus width="12" height="12"></IconPlus>
          </IconButton>
        </h3>
        <div class="form__row form__row--align-start">
          <div
            class="form__row_col form__row_col--side form__row_col--bordered"
            :class="{ 'form__row_col--error': sponsor.error }"
          >
            <span class="form__row_label">Sponsor Image</span>
            <DragAndDropImage
              class="uploader--full-width"
              id="add-sponsor"
              size="15"
              :image="sponsor.image"
              :v-model="sponsor.image"
              @input="
                sponsor.image = $event.image;
                sponsor.error = false;
                $event.removed ? removedImages.push($event.removed) : false;
              "
              @removeImage="
                removedImages.push($event);
                sponsor.image = '';
              "
            >
            </DragAndDropImage>
          </div>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Sponsor URL"
              :validateError="errors[0]"
              v-model="sponsor.url"
              :element="sponsor.url"
              @input="sponsor.url = $event"
            >
            </BaseInput>
          </ValidationProvider>
          <span v-if="sponsor.error" class="form__row_text form__row_text--error">
            This field is required
          </span>
        </div>
      </section>
      <section v-if="!item?.id" class="form">
        <h3 class="form__header">Add Timeslots</h3>
        <p class="form__header_notification">
          You can add a time slot after saving your changes. Please save first to enable this
          option.
        </p>
      </section>
      <section v-else>
        <AddTimelog
          v-if="item && item?.id"
          :id="item.id"
          :start_date="item.start_date"
          :end_date="item.end_date"
        />
        <Timeslots
          v-if="itemTimeslots.length > 0"
          :id="item.id"
          :items="itemTimeslots"
          :startDate="item.start_date"
          :endDate="item.end_date"
        ></Timeslots>
      </section>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseNumberInput from "@/components/inputs/BaseNumberInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";
import BaseTextarea from "@/components/inputs/BaseTextarea.vue";
import MultipleAutocompleteInput from "@/components/inputs/MultipleAutocompleteInput.vue";
import DragAndDropImage from "@/components/DragAndDropImage.vue";
import Editor from "@/components/inputs/Editor.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Toggle from "@/components/inputs/Toggle.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import SimpleToggle from "@/components/inputs/SimpleToggle.vue";
import Timeslots from "./components/Timeslots.vue";
import AddTimelog from "./components/AddTimelog.vue";

import IconButton from "@/components/buttons/IconButton.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import IconClose from "@/components/icons/IconClose.vue";

export default {
  props: {
    item: { type: Object, default: null },
    itemTimeslots: {
      type: Array,
      default() {
        return [];
      },
    },
    modal: { type: Boolean, default: false },
  },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseNumberInput,
    BaseTextarea,
    BaseSelect,
    DragAndDropImage,
    Editor,
    Toggle,
    DatePicker,
    IconButton,
    IconPlus,
    IconClose,
    SimpleToggle,
    Timeslots,
    AddTimelog,
    MultipleAutocompleteInput,
  },
  data() {
    return {
      url: process.env.VUE_APP_FRONTEND_URL,
      exhibition: {
        title: "",
        exhibition_type_id: "",
        slug: "",
        address: "",
        status: 0,
        start_date: "",
        end_date: "",
        schedule: "",
        description: "",
        home_description: "",
        guests_limit: 0,
        button_text: "",
        button_url: "",
        safety_protocol: "0",
        safety_protocol_info: "",
        search_image: "",
        header_image: "",
        home_page_image: "",
        copyright: "",
        catalog_title: "",
        catalog_description: "",
        catalog_image: "",
        catalog_button_text: "",
        catalog_button_url: "",
        sponsors: [],
        featured: false,
        button_rsvp_url: "",
        videos: {
          url: "",
          caption: "",
        },
      },
      sponsor: {
        image: "",
        url: "",
        error: false,
        text: "This field is required",
      },
      removedImages: [],
      changed: false,
    };
  },
  async created() {
    if (this.item != null) {
      this.exhibition = {
        ...JSON.parse(JSON.stringify(this.item)),
        videos: this.item.videos ? this.item.videos : { url: "", caption: "" },
      };
    }
  },
  beforeDestroy() {
    this.setError(null);
  },
  computed: {
    ...mapGetters("general", {
      exhibition_types: "getExhibitionTypes",
    }),
    ...mapGetters("exhibitions", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
    }),
    ...mapGetters("artists", {
      artists: "getSearchedArtists",
      artistsLoading: "getChangesLoading",
    }),
  },
  watch: {
    exhibition: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (this.$refs.observer.flags.dirty) {
            this.changed = true;
          }
          if (!val.status) {
            this.exhibition.featured = false;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
    item: {
      handler: function (val) {
        this.exhibition = val;
      },
    },
  },
  methods: {
    ...mapMutations("exhibitions", ["setError"]),
    ...mapActions("artists", ["apiGetSearchedArtists"]),

    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid &&
            this.exhibition.search_image != "" &&
            this.exhibition.home_page_image != ""
              ? this.saveExhibition()
              : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
    addSponsor({ image, url }) {
      if (image === "") {
        this.sponsor.error = true;
      } else {
        this.exhibition.sponsors.push({ image, url });
        this.sponsor = {
          ...this.sponsor,
          image: "",
          url: "",
        };
      }
    },
    removeSponsor(id) {
      this.exhibition.sponsors.splice(id, 1);
    },
    getSearchedArtists({ search, excludeIdx }) {
      this.apiGetSearchedArtists({ search, excludeIdx, status: 1 });
    },
    saveExhibition() {
      let data = {
        ...this.exhibition,
        artists: this.exhibition.artists
          ? this.exhibition.artists.map(el => {
              return el.id.toString();
            })
          : [],
      };
      if (this.exhibition.id) {
        this.$emit("updateExhibition", { data: data, imageForRemove: this.removedImages });
      } else {
        this.$emit("createExhibition", { data: data, imageForRemove: this.removedImages });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.exhibition {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      color: $black;
    }
    &_actions {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      @media only screen and (max-width: $xs) {
        justify-content: flex-start;
        width: 100%;
      }
      .button {
        margin-left: 10px;
        @media only screen and (max-width: $xs) {
          margin: 20px 10px 0 0;
        }
      }
    }
  }
  &__sponsor-button {
    width: max-content;
    min-width: 3.4rem;
    padding: 0 10px;
  }
}
</style>

