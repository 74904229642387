<template>
  <PopUp :show="modal" @hideModal="hideModal" class="modal--xxs" bgColor="rgba(0,0,0,0.4)">
    <ValidationObserver
      v-slot="{ invalid }"
      tag="div"
      ref="observer"
      class="reservation"
      id="scroll-block"
    >
      <header class="reservation__header">
        <h3 class="reservation__header_title">Edit Reservation</h3>
      </header>
      <section class="form reservation__body">
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="First Name"
              :required="true"
              :validateError="errors[0] || serverError.first_name"
              v-model="reservation.first_name"
              :element="reservation.first_name"
              @input="reservation.first_name = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Last Name"
              :required="true"
              :validateError="errors[0] || serverError.last_name"
              v-model="reservation.last_name"
              :element="reservation.last_name"
              @input="reservation.last_name = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Email"
              :required="true"
              :validateError="errors[0] || serverError.email"
              v-model="reservation.email"
              :element="reservation.email"
              @input="reservation.email = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider tag="div" class="form__row_col form__row_col--side">
            <BaseNumberInput
              placeholder="Number of guests"
              v-model="reservation.guests"
              :element="reservation.guests"
              :defaultValue="0"
              @input="reservation.guests = $event"
            ></BaseNumberInput>
          </ValidationProvider>
          <div class="form__row_col form__row_col--side">
            <DatePicker
              placeholder="Day"
              :required="true"
              :validateError="serverError.date"
              :value="reservation.date"
              @input="changeDate"
              format="YYYY-MM-DD"
              :time="false"
            ></DatePicker>
          </div>
        </div>
        <ValidationProvider tag="div" class="form__row" :rules="{ max: 1000 }" v-slot="{ errors }">
          <Editor
            placeholder="Notes"
            :value="reservation.notes"
            @input="reservation.notes = $event"
            :validateError="errors[0]"
            :toolbar="[
              'heading',
              'bold',
              'italic',
              'underline',
              'link',
              'blockQuote',
              '|',
              'alignment',
              'numberedList',
              'bulletedList',
            ]"
            v-model="reservation.notes"
          ></Editor>
        </ValidationProvider>
        <div class="form__row">
          <div class="form__row_col">
            <p class="title">Timeslot</p>
            <div class="timeslots">
              <button
                v-for="time in times"
                :key="time.value"
                class="timeslots-item"
                :class="{
                  'timeslots-item--selected': time.selected,
                }"
                @click="onTimeSlotClick(time.value)"
              >
                {{ time.value }}
              </button>
            </div>
          </div>
        </div>
      </section>
      <div class="reservation__actions">
        <BaseButton
          v-if="item"
          class="button--sm button--outline button--uppercase"
          text="Delete"
          @click="$emit('remove', reservation)"
        ></BaseButton>
        <BaseButton
          class="button--sm button--green button--uppercase"
          text="Save Changes"
          :loading="changesLoading"
          :disabled="invalid || !changed"
          @click="save"
        ></BaseButton>
      </div>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions, mapGetters, mapMutations } from "vuex";
import PopUp from "@/components/PopUp.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseNumberInput from "@/components/inputs/BaseNumberInput.vue";
import DatePicker from "@/components/inputs/DatePicker.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import Editor from "@/components/inputs/Editor.vue";

export default {
  props: {
    entityId: { type: Number, default: null },
    item: { type: Object, default: null },
    modal: { type: Boolean, default: false },
  },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseInput,
    BaseNumberInput,
    DatePicker,
    BaseButton,
    Editor,
  },
  data() {
    return {
      reservation: {
        rsvp: "",
        first_name: "",
        last_name: "",
        email: "",
        guests: "",
        date: "",
        time: null,
      },
      times: [],
      changed: false,
    };
  },
  watch: {
    reservation: {
      handler: function (val, oldVal) {
        if (this.item != null) {
          if (this.$refs.observer.flags.dirty) {
            this.changed = true;
          }
        } else {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  async created() {
    this.reservation = { ...JSON.parse(JSON.stringify(this.item)) };
    this.changePossibleTimes(this.item.date);
  },
  computed: {
    ...mapGetters("exhibitions", {
      serverError: "getServerError",
      changesLoading: "getChangesLoading",
      itemTimeslots: "getItemTimeslots",
      rsvp: "getRsvp",
    }),
  },
  methods: {
    ...mapActions("exhibitions", ["apiPutRsvp"]),
    changePossibleTimes(date) {
      const t = this.itemTimeslots
        .find(el => el.date === date)
        .times.filter(el => el.selected)
        .map(el => {
          if (el.value === this.item.time) {
            return { ...el, selected: true };
          } else return { ...el, selected: false };
        });
      this.times = t;
    },
    changeDate($event) {
      this.reservation.date = $event;
      this.changePossibleTimes($event);
    },
    async save() {
      const result = await this.apiPutRsvp({
        entity: this.entityId,
        rsvp: this.reservation.id,
        data: { ...this.reservation },
      });
      if (result) {
        this.$emit("updated");
        this.$emit("hideModal");
      }
    },
    onTimeSlotClick(time) {
      const t = this.times.map(item => {
        if (item.value === time) {
          return { ...item, selected: !item.selected };
        } else return { ...item, selected: false };
      });

      this.times = t;
      this.reservation.time = time;
      this.userInteraction = true;
    },
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.$refs.observer.flags.valid ? this.save() : this.showToast();
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
    showToast() {
      this.$toasted.show(`Please fill in all required fields`, {
        duration: 2000,
        type: "error",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reservation {
  height: 100%;
  padding: 0 15px;
  overflow-y: auto;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    @media only screen and (max-width: $xs) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_title {
      font-size: 3.35rem;
      font-weight: 600;
      line-height: 1.2;
      color: $black;
    }
  }
  &__body {
    max-height: 60vh;
    overflow-y: auto;
    padding-right: 10px;
  }
  &__actions {
    margin-top: 16px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: $xs) {
      justify-content: flex-start;
      width: 100%;
    }
  }
}
.timeslots {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 32px;
  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 22px;
    border-radius: 12px;
    border: 1px solid #707070;
    background: $white;
    @include type($fs: 14px, $lh: 1.25, $fw: 600);
    color: $black;
    &--selected,
    &:hover {
      border-color: #45755a;
      background: #45755a4d;
    }
  }
}
</style>

